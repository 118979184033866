define("discourse/plugins/discourse-dev-additions/discourse/templates/connectors/user-custom-preferences/employee-contract-start-date", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">
      {{i18n "discourse_dev_additions.employee_contract_start_date.title"}}
    </label>
    <div class="controls">
      {{date-input
        date=this.model.custom_fields.employee_contract_start_date
        onChange=(action "onChangeDate")
      }}
    </div>
  </div>
  */
  {
    "id": "i6HUK8/E",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"discourse_dev_additions.employee_contract_start_date.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"date\",\"onChange\"],[[30,0,[\"model\",\"custom_fields\",\"employee_contract_start_date\"]],[28,[37,4],[[30,0],\"onChangeDate\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"date-input\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-dev-additions/discourse/templates/connectors/user-custom-preferences/employee-contract-start-date.hbs",
    "isStrictMode": false
  });
});